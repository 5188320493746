
import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { CascaderOption } from '@/utils/common'

@Component({
  name: 'XCascader',
})
export default class extends Vue {
  /** 选中的值 */
  @Model('change', { type: Array, default: () => [] }) value!: string[]
  /** 选项 */
  @Prop({ default: () => [] }) options!: CascaderOption[]
  @Prop({ default: '请选择' }) placeholder!: string
  @Prop({ default: '' }) btnText!: string
  get values() {
    return this.value
  }

  /** 是否有新增按钮 */
  get hasBtn() {
    return !!this.btnText
  }

  props = { checkStrictly: true, expandTrigger: 'hover' }

  /** 点击 */
  onChange(val: []) {
    // 选择之后将下拉界面收起
    setTimeout(() => (this.$refs.cascader as any).toggleDropDownVisible(), 100)
    this.$emit('change', val)
  }

  /** 切换显示 */
  onVisibleChange(visible: boolean) {
    const ref = this.$refs.cascader as any
    const panel = ref.$children[1].$el as Element
    // console.log('pp', visible, ref, panel)
    const container = panel.querySelector('.el-scrollbar__view') as Element
    if (visible && this.hasBtn && !container.querySelector('.x-cascader-btn')) {
      const el = document.createElement('div')
      el.className = 'x-cascader-btn'
      el.innerText = this.btnText
      el.onclick = () => {
        this.$emit('add')
      }
      container.insertBefore(el, container.firstChild)
    }
  }
}
