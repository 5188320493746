
import { Component, Prop, Vue } from 'vue-property-decorator'
import CheckBox from '@/components/CheckBox.vue'
import XSvgIcon from '@/components/XSvgIcon.vue'

import type { TSurveyDomain } from '@/api/survey'
import type { OrderSurveyInfo } from '@/api/order'

@Component({
  components: { CheckBox, XSvgIcon },
  name: 'MItem',
})
export default class extends Vue {
  /** 调查项信息 */
  @Prop({ type: Object, default: {} }) info!: OrderSurveyInfo
  /** 调查项所属领域 */
  @Prop({ type: String, default: {} }) type!: TSurveyDomain
  /** 是否支持多选 */
  @Prop({ type: Boolean, default: true }) multiFlag!: boolean

  get icon() {
    switch (this.type) {
      case '00':
        return 'icon-work'
      case '01':
        return 'icon-edu'
      case '02':
        return 'icon-risk'
      case '03':
        return 'icon-cert'
      default:
        return ''
    }
  }

  onClick(e: Event) {
    e.stopPropagation()
    e.preventDefault()
  }
}
