import { alphaUrl, ajaxApi } from '@/utils/request';

/** 调查项所属领域，00：工作经历，01：风险查询，02：教育背景，03：资质证书 */
export type TSurveyDomain = '00' | '01' | '02' | '03';

/** 套餐内调查项 */
export type SurveyInfo = {
  /** 业务id，专属调查项时使用 */
  blzId: string
  /** 业务类型，00：通用，01：专属 */
  bizType: '00' | '01'
  /** 时间计算类型，00：并行，01：串行 */
  calculateType: '00' | '01';
  /** 预审规则，00：自动预审，01：人工预审，02：异常结果预审 */
  checkRule: '00' | '01' | '02';
  /** 说明 */
  description: string;
  /** 反馈时间，单位：小时 */
  feedbackTime: number;
  /** 是否可多选标识 */
  multiFlag: 0 | 1;
  /** 套餐id */
  packageId: string;
  /** 原价 */
  price: number;
  /** 销售类型 */
  saleType: '00' | '01'
  /** 状态，0：禁用，1：启用 */
  status: number;
  /** 调查范围，00：国内，01：港澳台，02：海外 */
  surveyArea: '00' | '01' | '02';
  /** 调查项所属领域，00：工作经历，01：风险查询，02：教育背景，03：资质证书 */
  surveyDomain: TSurveyDomain;
  /** 调查项id */
  surveyId: string;
  /** 调查项名称 */
  surveyName: string;
  /** 调查项的套餐内价格 */
  surveyPrice: number;
  /** 计价单位，00:元/人、01：元/段、02：元/候选人、03：元/证明人、04：元/证书、05：元/问题 */
  unit: '00' | '01' | '02' | '03' | '04' | '05';
  /** 是否选中 */
  checked: boolean;
  /** 是否禁止交互 */
  disabled: boolean;
};

/** 查询当前有效的合同 */
export const getSurveyInfo = () => ajaxApi('get', alphaUrl + '/survey/query/id') as Promise<SurveyInfo>;

/** 新增调查项 */
export const addSurvey = (params: SurveyInfo) => ajaxApi('post', alphaUrl + '/survey/add', params);
/** 更新调查项 */
export const updateSurvey = (params: SurveyInfo) => ajaxApi('post', alphaUrl + '/survey/update', params);
/** 删除调查项 */
export const removeSurvey = (params: Pick<SurveyInfo, 'surveyId'>) =>
  ajaxApi('post', alphaUrl + '/survey/delete', params);

/** 查询当前用户所有可用调查项 */
export const getSurveyList = () => ajaxApi('get', alphaUrl + '/survey/query/listByPkg') as Promise<SurveyInfo[]>;
