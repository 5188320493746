
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { OrderSurveyInfo } from '@/api/order'

@Component({
  name: 'Tags',
})
export default class extends Vue {
  /** 标题 */
  @Prop({ type: String, default: '' }) title!: string
  /** 调查项数组 */
  @Prop({ type: Array, default: [] }) list!: OrderSurveyInfo[]
}
