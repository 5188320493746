
import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { getOfficeTree } from '@/api/office'
import { toOption, CascaderOption } from '@/utils/common'
import XCascader from './XCascader.vue'
import OfficeEdit from '@/views/business/officeEdit.vue'

@Component({
  name: 'OfficeSelect',
  components: { XCascader, OfficeEdit },
})
export default class extends Vue {
  /** 选中的值 */
  @Model('change', { type: Array, default: () => [] }) value!: string[]
  @Prop({ default: '请选择' }) placeholder!: string
  get values() {
    return this.value
  }

  /** 选项 */
  options = [] as CascaderOption[]

  getOptions() {
    getOfficeTree().then(ls => {
      this.options = toOption(ls, { value: 'officeId', label: 'officeName' })
    })
  }

  onAdd() {
    ;(this.$refs.edit as OfficeEdit).show()
  }

  onUpdate(ids: string[]) {
    this.$emit('change', ids)
    this.getOptions()
  }

  created() {
    this.getOptions()
  }
}
