
import { Component, Vue } from 'vue-property-decorator'
import XBox from '@/components/XBox.vue'
import MItem from './MItem.vue'

import { getSurveyList, SurveyInfo } from '@/api/survey'
import { OrderSurveyInfo } from '@/api/order'

@Component({
  components: { XBox, MItem },
  name: 'OfficeEdit',
})
export default class extends Vue {
  /** 弹窗是否可见 */
  dialogVisible = false

  /*是否调查 */
  visibleExpe = false

  // layout = {
  //   xs: 8,
  //   sm: 6,
  //   md: 4,
  //   lg: 8,
  //   xl: 1,
  // }
  layout = {}

  /** 套餐调查项数据 */
  pkgItemList: SurveyInfo[] = []
  /** 调查项数据 */
  itemList: OrderSurveyInfo[] = []

  /** 工作经历 */
  get workList() {
    return this.itemList.filter(i => i.surveyDomain === '00')
  }

  /** 风险查询 */
  get riskList() {
    return this.itemList.filter(i => i.surveyDomain === '01')
  }

  /** 教育背景 */
  get eduList() {
    return this.itemList.filter(i => i.surveyDomain === '02')
  }

  /** 资质证书 */
  get certList() {
    return this.itemList.filter(i => i.surveyDomain === '03')
  }

  /** 名称包含学历或学位 */
  isXLorXW(name: string) {
    return /学[历位]/.test(name)
  }

  /** 获取调查项数据 */
  private async getItemList(pkgItems: SurveyInfo[], checkedItems: OrderSurveyInfo[]) {
    const ls = await getSurveyList()
    this.itemList = ls.map(i => {
      const checkedItem = checkedItems.find(c => c.surveyId === i.surveyId)
      return {
        ...i,
        num: checkedItem ? checkedItem.num : 1,
        checked: !!checkedItem,
        disabled: !!pkgItems.find(c => c.surveyId === i.surveyId),
      }
    })
    // console.log('xaxax', ls, pkgItems)
  }

  /** 不含工作调查项的选择子项 */
  show(pkgItems = [] as SurveyInfo[], checkedItems = [] as OrderSurveyInfo[]) {
    this.pkgItemList = pkgItems
    this.dialogVisible = true
    this.getItemList(pkgItems, checkedItems)
    this.visibleExpe = false
  }
  /** 含工作调查项的选择子项 */
  showDc(pkgItems = [] as SurveyInfo[], checkedItems = [] as OrderSurveyInfo[]) {
    this.pkgItemList = pkgItems
    this.dialogVisible = true
    this.getItemList(pkgItems, checkedItems)
    this.visibleExpe = true
  }

  /** 确认 */
  onOk() {
    const checkedItems = this.itemList.filter(i => i.checked)
    this.$emit('update', checkedItems)
    this.dialogVisible = false
  }
}
